<template>
  <div class="select">
    <div
      v-if="title"
      class="title-text mb-3"
    >
      {{ title }}
    </div>
    <v-select
      v-model="selectValue"
      :items="items"
      :label="label"
      :rules="rules"
      :placeholder="placeHolder"
      :outlined="outlined"
      :solo="solo"
      :filled="filled"
      :required="required"
    />
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    value: {
      type: String,
      default: () => '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => '',
    },
    placeHolder: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    solo: {
      type: Boolean,
      default: () => false,
    },
    filled: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('setValue', value);
      },
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.select {
  margin-bottom: 8px;

  .v-messages__wrapper {
    min-height: 0 !important;
  }
  .v-messages theme--light {
    min-height: 0 !important;
  }
  .v-text-field__details {
    min-height: 0 !important;
  }
  .v-messages {
    min-height: 0 !important;
  }
  fieldset {
    height: 48px !important;
  }
  .v-select__slot {
    height: 44px !important;
    margin-bottom: 0 !important;
  }
  .v-select__selections {
    padding: 0 !important;
  }
  .v-input__append-inner {
    margin-top: 10px !important;
  }
  .v-text-field__details {
    margin-bottom: 0 !important;
  }
  .v-input__slot {
    min-height: 0 !important;
    margin-bottom: 0 !important;
  }
  .title-text {
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: start;
  }
  .v-messages__message {
    margin: 12px 0 12px 0 !important;
  }
}
</style>
